<template>
  <section class="accordion-group">
    <header class="accordion-group__header">
      <icon-toggle-button
        :icon="allIsCollapsed ? 'unfold_more' : 'unfold_less'"
        :action="toggleAll"
        :tooltip="{ title: translations.accordionGroup[allIsCollapsed ? 'expand' : 'collapse'] }"
        button-size="medium"
      />
      {{ title ?? translations.accordionGroup[allIsCollapsed ? "expand" : "collapse"] }}
    </header>
    <slot :expansion="expansion" />
  </section>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";

import { translations } from "@/infrastructure/translations";

import IconToggleButton from "@/components/buttons/icon-toggle-button.vue";

type SectionSetter = (isExpanded: boolean) => void;

export type GroupExpansion = {
  toggle: (id: string, isExpanded: boolean) => void;
  unmount: (id: string) => void;
  mount: (id: string, isExpanded: boolean, set: SectionSetter) => void;
};

defineProps<{ title?: string }>();

const states = ref<{ [key: string]: { isExpanded: boolean; set: SectionSetter } }>({});

const allIsCollapsed = computed(() => !Object.values(states.value).some((a) => a.isExpanded === true));
const allIsExpanded = computed(
  () => Object.values(states.value).length >= 1 && !Object.values(states.value).some((a) => a.isExpanded === false),
);

const toggleAll = () => Object.values(states.value).forEach(({ set }) => set(allIsCollapsed.value));

const expansion: GroupExpansion = {
  toggle: (id, isExpanded) => {
    states.value[id].isExpanded = isExpanded;
  },
  unmount: (id) => {
    delete states.value[id];
  },
  mount: (id, isExpanded, set) => {
    if (allIsExpanded.value) {
      set(true);
    }
    states.value[id] = { isExpanded, set };
  },
};
</script>

<style lang="scss" scoped>
.accordion-group {
  display: flex;
  flex-flow: column;
  &__header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 2px;
  }
}
</style>
